<template>
  <div>
    <div v-if="Loaderstatus" class="relativeclass">
      <div class="spinnerstyle">
        <Spinner />
      </div>
    </div>

    <div v-else class="login-main-container" :style="!finishButton ? '' : ''">
      <div class="h-100 d-flex justify-content-center align-items-center">
        <!-- do bind the style vth height if the typing test is over -->
        <div
          class="login-main-sub-container position-relative p-0"
          style="height: calc(100vh - 20px)"
        >
          <img
            v-if="!finishButton"
            class="typingtest-container-icon"
            src="../assets/newui-img/typingtest.svg"
            alt=""
          />

          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 py-3 d-flex justify-content-center align-items-baseline"
              >
                <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
              </div>
            </b-col>
          </b-row>
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <!-- do bind the h-100 d-flex  flex-column align-items-center text-center class if the typing test is over -->
              <div
                class="p-3 testdetails-container"
                :class="
                  finishButton
                    ? 'ttblue-conatiner-height d-flex flex-column align-items-center text-center'
                    : 'ttblue-conatiner-height3'
                "
              >
                <!-- <img
                  v-if="!finishButton"
                  class="typingtest-container-belowicon"
                  src="../assets/newui-img/typingtest2.svg"
                  alt=""
                /> -->
                <!-- //use this fr typing test  -->

                <div
                  class="position-relative"
                  style="height: 95%"
                  v-if="!finishButton"
                >
                  <h1 class="login-rightcont-headtxt">
                    Typing Test
                  </h1>
                  <b-row style="height: 95%">
                    <b-col cols="9">
                      <div
                        style="height: 48%"
                        class="mt-3 typingtest-paragraph-container p-3 w-100 text-left"
                      >
                        <div class="h-100 overflow-auto" v-if="!finishButton" style="text-align: justify;">
                          <div
                            id="quote"
                            class="h-100 typingtest-para-txt mr-1"
                            onmousedown="return false"
                            onselectstart="return false"
                          ></div>
                        </div>
                      </div>
                      <div
                        style="height: 49%"
                        class="mt-3 typingtest-paragraph-container2 typingtest-typed-txtarea p-3 w-100 text-left position-relative"
                      >
                        <div class="start-typing-label px-2">
                          Start typing
                        </div>
                        <div>
                          <div class="tt-tooltip" v-if="time === 60">
                            Timer will start once you start typing...
                          </div>
                          <div v-else class="tt-tooltip" v-show="false"></div>
                        </div>
                        <div class="h-100" v-if="!finishButton">
                          <b-textarea
                            rows="7"
                            type="text"
                            max-rows="10"
                            id="quote-input"
                            class="h-100"
                            @keyup.once="startTest()"
                            placeholder="Type here..."
                            @keyup="initTyping()"
                            @copy="preventCopyPaste"
                            @cut="preventCopyPaste"
                            @paste="preventCopyPaste"
                          ></b-textarea>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="3">
                      <div class="h-100">
                        <div style="height: 17%"></div>
                        <div
                          style="height: 85.7%"
                          class="position-relative testdetails-rightcards p-3 d-flex flex-column justify-content-around align-items-center"
                        >
                          <div
                            style="
                              position: absolute;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              top: -4%;
                              filter: drop-shadow(
                                0px 15px 30px rgba(122, 122, 122, 0.15)
                              );
                            "
                            class="d-flex justify-content-center align-items-center"
                          >
                            <div
                              style="
                                width: 150px;
                                height: 150px;
                                padding: 5px;
                                border-radius: 50%;
                                background: #fff;
                                display: grid;
                                place-content: center;
                                place-items: center;
                              "
                            >
                              <div
                                class="typingtest-progress-container"
                                :style="
                                    time <= 15
                                        ? remainingtimeStyle
                                        :time<=30? remaininghalftimeStyle : progressBarStyle
                                "
                              >
                                <div class="typingtest-valueprogress">
                                  <div class="time-txt">Time</div>
                                  <span id="timer">{{
                                    testDetails.config.typingDuration
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style="width: 140px"
                            class="d-flex align-items-center mt-5"
                          >
                            <div>
                              <img
                                src="../assets/newimgs/typingtest/typingspeed.svg"
                                alt=""
                              />
                            </div>
                            <div class="ml-2">
                              <h3 class="typingtest-card-head">Typing Speed</h3>
                              <div class="typingtest-right-subtxt">
                                <span id="wpm">0</span>WPM
                              </div>
                            </div>
                          </div>
                          <div
                            style="width: 133px"
                            class="d-flex align-items-center"
                          >
                            <div>
                              <img
                                src="../assets/newimgs/typingtest/accuracy.svg"
                                alt=""
                              />
                            </div>
                            <div class="ml-2">
                              <h3 class="typingtest-card-head">Accuracy</h3>
                              <div class="typingtest-right-subtxt">
                                <span id="accuracy">0</span> %
                              </div>
                            </div>
                          </div>
                          <div
                            style="width: 130px"
                            class="d-flex align-items-center"
                          >
                            <div>
                              <img
                                src="../assets/newimgs/typingtest/mistake.svg"
                                alt=""
                              />
                            </div>
                            <div class="ml-2">
                              <h3 class="typingtest-card-head">Mistakes</h3>
                              <div class="typingtest-right-subtxt">
                                <span id="mistakes">0</span>!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <div
                  v-else
                  class="w-75 d-flex justify-content-center flex-column"
                >
                  <h1 class="login-rightcont-headtxt pt-3">Completed Successfully</h1>
                  <div class="mt-3">
                    <!-- <h4 class="typingtest-completed-txt">
                      You have completed the typing test
                    </h4> -->
                    <div
                      class="d-flex align-items-center justify-content-center flex-column"
                    >
                      <div class="w-50">
                        <div
                          class="mt-4 typingtest-completed-cards p-3 position-relative"
                        >
                          <img
                            class=""
                            src="../assets/newui-img/cmpltdsuccess.svg"
                            alt=""
                          />
                          <div>
                            <div class="completed-box-heading mt-2">
                              You have successfully completed your
                              Typing test
                            </div>

                            <!-- <img
                              src="../assets/newimgs/typingtest/completed.svg"
                              alt=""
                            /> -->
                            <div class="tt-completed-sub-box mb-3 d-flex justify-content-around align-items-center mt-3">
                              <div class="align-items-center">
                                <div>
                                  <img
                                    src="../assets/newimgs/typingtest/typingspeed.svg"
                                    alt=""
                                  />
                                </div>
                                <div
                                  class="align-items-baseline flex-column ml-1"
                                >
                                  <h3 class="typingtest-card-head">
                                    Typing Speed
                                  </h3>
                                  <div class="typingtest-right-subtxt">
                                    <span id="wpm">{{
                                      analysisReport.wpm
                                    }}</span>
                                 <span class="wpm-txt"> WPM</span> 
                                  </div>
                                </div>
                              </div>
                              <div class=" align-items-center">
                                <div>
                                  <img
                                    src="../assets/newimgs/typingtest/accuracy.svg"
                                    alt=""
                                  />
                                </div>
                                <div
                                  class="align-items-baseline flex-column ml-1"
                                >
                                  <h3 class="typingtest-card-head">
                                    Accuracy
                                  </h3>
                                  <div class="typingtest-right-subtxt">
                                    <span id="accuracy">{{
                                      analysisReport.accuracy
                                    }}</span
                                    >%
                                  </div>
                                </div>
                              </div>
                              <div class=" align-items-center">
                                <div>
                                  <img
                                    src="../assets/newimgs/typingtest/mistake.svg"
                                    alt=""
                                  />
                                </div>
                                <div
                                  class=" align-items-baseline flex-column ml-1"
                                >
                                  <h3 class="typingtest-card-head">
                                    Mistakes
                                  </h3>
                                  <div class="typingtest-right-subtxt">
                                    <span id="mistakes">{{
                                      analysisReport.mistakes
                                    }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <h3 class="typingtest-card-head">Total Time Taken</h3> -->
                          <!-- <span class="testdetails-simpledetails-subtxt">
                            60 seconds
                          </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mb-0 mt-1" />
              <div
                class="p-3 d-flex align-items-center justify-content-between bluebtns"
              >
                <b-button
                  variant="outline-primary"
                  @click="$bvModal.show('leavetestmodal')"
                  >Leave Test</b-button
                >
                <b-button
                  v-if="finishButton"
                  variant="primary"
                  class="pl-4 pr-4"
                  @click="next()"
                  >Continue</b-button
                >
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="cutcpoypaste"
          class="notification d-flex justify-content-between align-items-center mr-2"
        >
          <div style="color: white" class="ml-2">
            Cut copy paste is not allowed
          </div>
          <div class="pointer mr-2 close-notify">
            <img
              @click="closeNotification()"
              src="../assets/newimgs/closeicon.svg"
              alt=""
            />
          </div>
        </div>
        <b-modal
        id="leavetestmodal"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert !
        </div>
        <div
          @click="$bvModal.hide('leavetestmodal')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newui-img/leavetest.svg" alt="" />
          </div>
          <div class="newui-modal-header mt-4">
            Are you sure you want to leave the test ?
          </div>
          <div class="newui-modal-bodytxt mt-3">
            Please note this action cannot be undone
          </div>
          <div class="bluebtns mt-5">
            <b-button
              class="mr-3 pl-4 pr-4"
              variant="outline-primary"
              @click="$bvModal.hide('leavetestmodal')"
              >Cancel</b-button
            >
            <b-button variant="primary" class="pl-4 pr-4" @click="leave()"
              >Leave Test</b-button
            >
          </div>
        </div>
      </b-modal>
      <b-modal id="systemcheckmodal" centered hide-footer hide-header size="xl">
        <div
          class="newmodalheadings justify-content-center text-center d-flex align-items-center"
        >
          Enable System Permissions
        </div>
        <div
          @click="$bvModal.hide('systemcheckmodal')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>

        <div class="mt-5 ml-3">
          <b-row>
            <b-col>
              <div class="mt-2 step-subtext">
                1. Please Click on view site information to allow all
                permissions
              </div>
              <div class="mt-2">
                <img src="../assets/images/step1.svg" alt="" />
              </div>
            </b-col>
            <b-col>
              <div class="mt-2 step-subtext">
                2. Please Enable all permissions
              </div>
              <div class="mt-2">
                <img src="../assets/images/step2.svg" alt="" />
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4 ml-3">
          <b-row>
            <b-col>
              <div class="mt-2 step-subtext">
                3. After enabling Permissions, Reload the page
              </div>
              <div class="mt-2">
                <img src="../assets/images/step3.svg" alt="" />
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          class="bluebtns d-flex align-items-center justify-content-center mt-4"
        >
          <b-button
            variant="primary"
            class="px-5"
            @click="$bvModal.hide('systemcheckmodal')"
            >Okay</b-button
          >
        </div>
      </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { postTypingResult, getTypingResult } from "../apiFunction.js";
import { mapGetters } from "vuex";
import Spinner from "../components/fixedcomponents/Spinner.vue";

export default {
  name: "TypingTest",
  components: {
    Spinner,
  },
  data() {
    return {
      cutcpoypaste: false,
      time: 60,
      mistakes: 0,
      quote: "",
      timer: "",
      charIndex: 0,
      isTyping: 0,
      progressValue: 100,
      progressEndValue: 0,
      userInput: "",
      speed: "",
      accuracy: "",
      wordsPerMinute: "",
      accuracyInPercentage: "",
      finishButton: false,
      durationInSeconds: null,
      Loaderstatus: false,
      remainingTime: 0,
      timeElapsed: 0,
      analysisReport: {
        wpm: null,
        accuracy: null,
        mistakes: null,
      },
    };
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      userDetials: "getUserDetails",
      uuid: "getUUID",
    }),
    progressBarStyle() {
      return {
        background: `conic-gradient(
        #68B15C ${this.progressValue * 3.6}deg,
        #cadcff ${this.progressValue * 3.6}deg)`,
      };
    },
    remainingtimeStyle() {
      return {
        background: `conic-gradient(
          red ${this.progressValue * 3.6}deg,
          #cadcff ${this.progressValue * 3.6}deg
        )`,
      };
    },
    remaininghalftimeStyle() {
      return {
        background: `conic-gradient(
            #FFC145 ${this.progressValue * 3.6}deg,
            #cadcff ${this.progressValue * 3.6}deg
            )`
      };
    },
  },
  mounted() {
    this.windowReload();
    this.disableCopy();
  },
  created() {
    this.Loaderstatus = true;
    this.getTypingResult();
    this.askCameraPermission();
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
  },
  methods: {
    preventCopyPaste(event) {
      event.preventDefault();
      this.cutcpoypaste = true;
      setTimeout(() => {
        this.cutcpoypaste = false;
      }, 4000);
    },
    preventRightClick(event) {
      event.preventDefault();
    },
    disableCopy() {
      document.onselectstart = () => false;

      if (window.sidebar) {
        document.onmousedown = this.killCopy;
        document.onclick = this.reEnable;
      }
    },
    enableCopy() {
      document.onselectstart = null;
      document.onmousedown = null;
      document.onclick = null;
    },
    killCopy(e) {
      console.log(e);
      return false;
    },
    reEnable() {
      return true;
    },
    async getTypingResult() {
      const res = await getTypingResult(
        this.testDetails.topicID,
        this.testDetails.testID,
        this.userDetials.email,
        this.testDetails.attemptNo,
        this.testDetails.groupID
      );
      this.Loaderstatus = false;
      this.quote = await res.data.data.paragraph;
      let completed = res.data.data.completed;
      if (completed) {
        this.finishButton = true;
        this.analysisReport = res.data.data.analysisResult;
        this.durationInSeconds = res.data.data.durationInSeconds;
      } else {
        this.finishButton = false;
      }
      this.renderNewQuote();
    },
    async next() {
      const isCameraGranted = await this.checkPermissionGranted("camera");
      const isMicroGranted = await this.checkPermissionGranted("microphone");
      if (!isCameraGranted || !isMicroGranted) {
        this.$bvModal.show("systemcheckmodal");
      } else if (this.testDetails.config.communicationTest === true) {
        this.$router.push({
          path: "/communicationtest",
        });
      } else {
        this.$router.push({
          path: "/dosanddonts",
        });
      }
    },
    async checkPermissionGranted(key) {
      try {
        const permission = await navigator.permissions.query({ name: key });
        return permission.state === "granted";
      } catch (error) {
        return false;
      }
    },
    askCameraPermission() {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
    },
    leave() {
      if(localStorage.getItem("fromCandidate")){
        window.close('','_parent','');
      }
      localStorage.clear();
   
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid,
        },
      });
    },
    async postTypingResult(payload) {
      let data = {
        topicID: this.testDetails.topicID,
        testID: this.testDetails.testID,
        groupID: this.testDetails.groupID,
        typingAnalysisResult: {
          wpm: this.speed,
          accuracy: this.accuracy,
          mistakes: this.mistakes,
          answer: payload,
          attemptNo: this.testDetails.attemptNo,
        },
        attemptNo: this.testDetails.attemptNo,
      };
      const res = await postTypingResult(data);
      this.getTypingResult();
      console.log(res);
    },

    initTyping() {
      const userInput = document.getElementById("quote-input");
      //Logic for comparing input words with quote
      let quoteChars = document.querySelectorAll(".quote-chars");
      //Create an arrat from received span tags
      quoteChars = Array.from(quoteChars);
      this.mistakes = 0;

      //array of user input characters
      let userInputChars = userInput.value.split("");
      //loop through each character in quote
      quoteChars.forEach((char, index) => {
        //Check if char(quote character) = userInputChars[index](input character)
        if (char.innerText == userInputChars[index]) {
          char.classList.remove("typing-test-para-txt-wronganswer");
          char.classList.add("typing-test-para-txt-crctanswer");
        }
        //If user hasn't entered anything or backspaced
        else if (userInputChars[index] == null) {
          //Remove class if any
          char.classList.remove("typing-test-para-txt-crctanswer");
          char.classList.remove("typing-test-para-txt-wronganswer");
        }
        //If user enter wrong character
        else {
          this.mistakes += 1;
          char.classList.add("typing-test-para-txt-wronganswer");
        }
        //Returns true if all the characters are entered correctly
      });
      let check = quoteChars.every((element) => {
        return element.classList.contains("typing-test-para-txt-crctanswer");
      });
      //End test if all characters are correct
      if (check) {
        this.postTypingResult(document.getElementById("quote-input").value);
        this.displayResult();
      }
      document.getElementById("mistakes").innerText = this.mistakes;
      this.liveChangeInResults(userInput);
    },

    liveChangeInResults(userInput) {
      if (
        userInput.value === null ||
        userInput.value === undefined ||
        !userInput.value ||
        document.getElementById("wpm").innerText === "Infinity"
      ) {
        this.accuracyInPercentage = "";
        this.wordsPerMinute = "";
        document.getElementById("wpm").innerText = "0";
        document.getElementById("accuracy").innerText = "0";
        document.getElementById("mistakes").innerText = "0";
      } else if (
        userInput.value &&
        (document.getElementById("wpm").innerText < 0 ||
          document.getElementById("accuracy").innerText < 0)
      ) {
        this.accuracyInPercentage = "";
        this.wordsPerMinute = "";
        document.getElementById("wpm").innerText = "0";
        document.getElementById("accuracy").innerText = "0";
      } else {
        this.accuracyInPercentage = document.getElementById(
          "accuracy"
        ).innerText = Math.round(
          ((userInput.value.length - this.mistakes) / userInput.value.length) *
            100
        );
        let wpm = Math.round(
          (userInput.value.length / 5 / this.timeElapsed) *
            (this.accuracyInPercentage / 100)
        );
        this.wordsPerMinute = document.getElementById("wpm").innerText =
          wpm === Infinity || isNaN(wpm) ? "0" : wpm;
      }
    },

    //End Test
    displayResult() {
      //display result div
      const userInput = document.getElementById("quote-input");
      clearInterval(this.timer);
      userInput.disabled = true;
      if (
        userInput.value === null ||
        userInput.value === undefined ||
        !userInput.value
      ) {
        this.speed = "0";
        this.accuracy = "0";
      } else {
        this.accuracy = this.accuracyInPercentage;
        this.speed = Math.round(
          (userInput.value.length / 5 / this.timeElapsed) *
            (this.accuracy / 100)
        ).toString();
      }
    },

    //Display random quotes
    renderNewQuote() {
      const quoteSection = document.getElementById("quote");
      //Access quote
      //Array of characters in the quote
      let arr = this.quote.split("").map((value) => {
        //wrap the characters in a span tag
        return "<span class='quote-chars'>" + value + "</span>";
      });
      if (document.getElementById("quote") !== null) {
        //join array for displaying
        quoteSection.innerHTML += arr.join("");
      }
    },

    //Update Timer on screen
    updateTimer() {
      if (this.time === 0) {
        //End test if timer reaches 0
        this.finishButton = true;
        this.displayResult();
        this.postTypingResult(document.getElementById("quote-input").value);
      } else {
        this.remainingTime = document.getElementById("timer").innerText =
          --this.time + "s";
        this.timeElapsed = ((60 - this.time) / 60).toFixed(3);
      }
    },

    //Sets timer
    timeReduce() {
      const userInput = document.getElementById("quote-input");
      this.time = this.testDetails.config.typingDuration;
      this.timer = setInterval(() => {
        if (this.progressValue > this.progressEndValue) {
          this.updateTimer();
          this.progressValue = this.progressValue - 1.656;
          if (
            userInput.value === null ||
            userInput.value === undefined ||
            !userInput.value
          ) {
            document.getElementById("wpm").innerText = "0";
          } else {
            document.getElementById("wpm").innerText = Math.round(
              (userInput.value.length / 5 / this.timeElapsed) *
                (this.accuracyInPercentage / 100)
            );
          }
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },

    //Start Test
    startTest() {
      this.mistakes = 0;
      this.timer = "";
      this.timeReduce();
    },

    windowReload() {
      this.renderNewQuote();
    },
  },
};
</script>

<style>
.typingtest-para-txt span {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: 21px;
  text-align: justify;
  color: #1e2022;
}
.typingtest-typed-txtarea .form-control {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: 21px;
  text-align: justify;
  color: #1e2022;
  border: none;
}
.tt-tooltip {
  position: absolute;
  top: 60px;
  border-radius: 5px;
  border: 1px solid #4c78ce;
  background: #fff;
  box-shadow: 0px 15px 30px 0px rgba(122, 122, 122, 0.2);
  color: #4c78ce;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  padding: 5px 10px;
}

.tt-tooltip::before {
  content: "";
  position: absolute;
  left: 19px;
  top: -5px;
  transform: translateX(-50%) rotate(225deg);
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-right-color: #4c78ce;
  border-bottom-color: #4c78ce;
  background-color: white;
  z-index: 1;
}
.notification {
  position: fixed;
  top: 8% !important;
  border-radius: 5px;
  width: 260px;
  height: 50px;
  background: red;
  text-align: center;
  right: 50%;
  transform: translateX(50%);
  z-index: 1000;
  animation: slideIn 0.5s ease-out forwards;
}
@keyframes slideIn {
  from {
    top: -100px;
  }
  to {
    top: 8%;
  }
}
.close-notify {
  background: white;
  border-radius: 50px;
}
</style>

